import { ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import GA4Button from '../../ga4-button';
import { GA4EventProps } from '../../with-ga4-event';

interface FNCButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, GA4EventProps {
  icon?: JSX.Element;
  iconPosition?: 'start' | 'end';
}

export default function FNCButton({
  className,
  type = 'button',
  children,
  disabled,
  icon,
  iconPosition = 'start',
  ...props
}: FNCButtonProps): JSX.Element {
  return (
    <GA4Button
      className={twMerge(
        clsx(
          'flex min-h-[40px] min-w-[120px] items-center justify-center gap-x-2 rounded-md px-6 py-2 text-center font-semibold',
          '[&:not(:disabled)]:hover:brightness-90',
          className,
          { 'opacity-[0.38]': disabled },
        ),
      )}
      type={type}
      disabled={disabled}
      {...props}
    >
      {icon && iconPosition === 'start' && (
        <span className="[&>svg]:text-20" data-testid="start-icon">
          {icon}
        </span>
      )}

      {children && <span>{children}</span>}

      {icon && iconPosition === 'end' && (
        <span className="[&>svg]:text-20" data-testid="end-icon">
          {icon}
        </span>
      )}
    </GA4Button>
  );
}
