import React, { HTMLAttributes } from 'react';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { USPAttributes } from 'shared/types/product/USP';
import Link from '~/components/furniturechoice/link';
import RichTextContentful from '~/components/furniturechoice/rich-text/contentful';

export interface BaseUSPProps extends HTMLAttributes<HTMLDivElement> {
  data: USPAttributes;
}

export const uspOptions: Options = {
  renderNode: {
    // does not use <p> because <div> is not allowed to be a child element of <p>
    [BLOCKS.PARAGRAPH]: (_node, children) => <div className="leading-5">{children}</div>,

    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('tel')) {
        console.log({ node, children });
      }
      return (
        <Link className="text-salmon-4 hover:underline" href={node.data.uri}>
          {children}
        </Link>
      );
    },
  },
};

export const uspFreeReturnOptions: Options = {
  renderNode: {
    ...uspOptions.renderNode,
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('tel')) {
        return (
          <Link
            ga4_event_name="contact_us"
            ga4_event_param_1_name="interaction_type"
            ga4_event_param_1_value="Phone"
            ga4_event_param_2_name="element"
            ga4_event_param_2_value="Modal Free Returns"
            className="text-salmon-4 hover:underline"
            href={node.data.uri}
          >
            {children}
          </Link>
        );
      }
      if (node.data.uri.startsWith('mailto')) {
        return (
          <Link
            ga4_event_name="contact_us"
            ga4_event_param_1_name="interaction_type"
            ga4_event_param_1_value="Email"
            ga4_event_param_2_name="element"
            ga4_event_param_2_value="Modal Free Returns"
            className="text-salmon-4 hover:underline"
            href={node.data.uri}
          >
            {children}
          </Link>
        );
      }
      return (
        <Link className="text-salmon-4 hover:underline" href={node.data.uri}>
          {children}
        </Link>
      );
    },
  },
};

interface WrapperUSPProps extends BaseUSPProps {
  children: JSX.Element | JSX.Element[];
}

export function USPWrapper({ data, children }: WrapperUSPProps): JSX.Element {
  return (
    <div
      id={'usp_content_' + data.type}
      className="h-full overflow-y-auto text-grey-5 scrollbar-hide"
      data-usp-type={data.type}
    >
      <h2 className="pb-2 text-20 font-bold text-grey-6">{data.title.toString()}</h2>
      {children}
    </div>
  );
}

export function USPContent({ data, ...props }: BaseUSPProps): JSX.Element {
  return (
    <USPWrapper data={data} {...props}>
      <div>
        <RichTextContentful
          data={data.text}
          options={data?.key === 'free-returns' ? uspFreeReturnOptions : uspOptions}
        />
      </div>
    </USPWrapper>
  );
}

export default USPContent;
